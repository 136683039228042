import Container from "./Container";
import {navLinks} from "../helpers/links";
import LogoLink from "./LogoLink";
import Button from "./Button";
import {generateScheduleMailto} from "../helpers/strings";
import {useEffect, useState} from "react";

export default function Nav({className = '', ...rest}) {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 100);
        }

        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav
            className={`nav md:pointer-events-none ${scrolled ? 'nav-scrolled' : ''} ${className}`}
            {...rest}
        >
            <Container className={`flex items-center justify-between transition-all duration-300 ${scrolled ? 'py-3 md:py-6' : 'py-6'}`}>
                <div>
                    <LogoLink className="pointer-events-auto"/>
                </div>
                <div className="pointer-events-auto">
                    {navLinks.map(({href, label}, key) => {
                        return (
                            <Button key={`NavLink: ${key}`}
                                    href={generateScheduleMailto()}
                                    hideArrow={true}
                            >
                                {label}
                            </Button>
                        )
                    })}
                </div>
            </Container>
        </nav>
    )
}