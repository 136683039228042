export const navLinks = [
    {
        href: '/',
        label: 'Schedule a meeting'
    }
];

export const burgerLinks = navLinks;

export const footerLinks = [
    {
        href: "mailto:hello@pureducation.com",
        label: "hello@pureducation.com"
    },
    {
        href: "tel:+420606460740",
        label: "+420 606 460 740"
    },
];

export const socialLinks = {
    facebook: {
        href: 'https://www.facebook.com/pureducation/'
    },
    instagram: {
        href: 'https://www.instagram.com/wearepuredu/'
    },
    whatsapp: {
        href: 'https://api.whatsapp.com/send?phone=420606460740'
    },
    // twitter: {
    //     href: 'https://twitter.com/WeArePuredu'
    // }

}