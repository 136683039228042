import Link from "next/link";
import SvgLogo from "../svg/SvgLogo";
import {useAppContext} from "../context/AppContext";

export default function LogoLink({className = '', ...rest}) {
    const {dispatch} = useAppContext();

    const handleClick = (e) => {
        e.preventDefault();
        dispatch({type: 'scroll_to_top'});
    }

    return (
        <Link href="/"
              aria-label="Go to homepage"
              className={className}
              onClick={handleClick}
              {...rest}
        >
            <SvgLogo width={110}/>
        </Link>
    )
}