import '../styles/globals.css'
import {AppLayout} from "../layouts/AppLayout";
import {AppWrapper} from "../context/AppContext";
import {gsap} from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {Analytics} from '@vercel/analytics/react';

gsap.registerPlugin(ScrollTrigger);

function MyApp({Component, pageProps}) {
    return (
        <AppWrapper>
            <AppLayout>
                <Component {...pageProps} />
                <Analytics/>
            </AppLayout>
        </AppWrapper>
    )
}

export default MyApp
