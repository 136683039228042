import Link from "next/link";
import SvgArrowRight from "../svg/SvgArrowRight";

export default function Button({className = '', size = 'base', hideArrow = false, href, children, ...rest}) {
    const classes = `button button-${size} ${className}`;

    if (href) {
        return (
            <Link href={href}
                  className={classes}
                  {...rest}
            >
                <span>{children}</span>
                <SvgArrowRight className={`button-arrow ${hideArrow ? 'xs:hidden' : ''}`}/>
            </Link>
        )
    }
    return (
        <button className={classes}
                {...rest}
        >
            <span className="mr-3">{children}</span>
            <SvgArrowRight className={`button-arrow ${hideArrow ? 'xs:hidden' : ''}`}/>
        </button>
    )
}