export const initialState = {
    burgerMenuActive: false,
    burgerMenuOffset: 0
};

export const AppReducer = (state, action) => {
    switch (action.type) {
        case "reset_state": {
            return initialState;
        }
        case "scroll_to_contact": {
            const contact = document.getElementById('contact');

            if (contact) {
                setTimeout(() => {
                    contact.scrollIntoView({
                        behavior: 'smooth',
                    });
                }, 200);
            }

            return state;
        }
        case "scroll_to_top": {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 200);

            return state;
        }
        case "open_burger_menu": {
            return {
                ...state,
                burgerMenuActive: true,
                burgerMenuOffset: action.value
            }
        }
        case "close_burger_menu": {
            return {
                ...state,
                burgerMenuActive: false,
            }
        }
        default: {
            console.error('Unknown action type', action.type);
            return state;
        }
    }
};
