export function generateMailTo(email, subject, body) {
    let mailto = `mailto:${email}`;

    if (subject || body) {
        mailto += '?';
    }

    if (subject) {
        mailto += `subject=${subject}`;
    }

    if (subject && body) {
        subject = encodeURIComponent(body);
        mailto += '&';
    }

    if (body) {
        body = encodeURIComponent(body);
        mailto += `body=${body}`;
    }


    return mailto;
}

export function generateScheduleMailto() {
    const email = "hello@pureducation.com";
    const subject = "Let's collaborate!";
    const body = `Hi Puredu team,

My name is [YOUR NAME] and I am interested in working with you.
I am a [YOUR PROFESSION] living in [COUNTRY].

Thank you!`;

    return generateMailTo(email, subject, body);
}