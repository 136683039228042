import Container from "./Container";
import {getFullYear} from "../helpers/date";
import Link from "next/link";
import {footerLinks} from "../helpers/links";
import LogoLink from "./LogoLink";

export default function Footer({className = '', ...rest}) {
    return (
        <footer className={`text-base text-gray py-32 border-t border-body ${className}`} {...rest}>
            <Container className="flex flex-col md:flex-row items-start justify-between">
                <div className="flex-1 flex flex-col">
                    <LogoLink className="text-white mb-4"/>
                    <p>
                        &copy; {getFullYear()} RAW Entertainment s.r.o.
                    </p>
                </div>

                <address className="flex-1 not-italic flex flex-col my-32 md:my-0">
                    <p className="mb-2">RAW Entertainment s.r.o.</p>
                    <p className="mb-2">Prague, Czech Republic</p>
                    <p>VAT ID: CZ06039839</p>
                </address>

                <div className="flex-1 max-w-[240px] flex flex-col">
                    {footerLinks.map(({href, label}, key) => {
                        return (
                            <Link href={href}
                                  key={`FooterLink: ${key}`}
                                  className="inline-flex text-gray mouse-hover:text-white mb-2 transition-colors duration-200"
                            >
                                {label}
                            </Link>
                        )
                    })}
                </div>
            </Container>
        </footer>
    )
}