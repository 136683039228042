import Nav from "../components/Nav";
import Footer from "../components/Footer";
import {useAppContext} from "../context/AppContext";
import BurgerMenu from "../components/BurgerMenu";
import {useEffect} from "react";

import {ReactLenis} from "@studio-freight/react-lenis";

export function AppLayout({className = '', children}) {
    const {state, dispatch} = useAppContext();
    const {burgerMenuActive, burgerMenuOffset} = state;

    useEffect(() => {
        if (!burgerMenuActive) {
            window.scrollTo(0, parseInt(burgerMenuOffset || '0'));
        }
    }, [burgerMenuActive, burgerMenuOffset]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768 && burgerMenuActive) {
                dispatch({type: 'close_burger_menu'})
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);

    }, [burgerMenuActive, dispatch]);

    return (
        <ReactLenis root
                    options={{
                        duration: 1.5
                    }}
        >
            <div
                className={`relative w-full flex flex-col ${burgerMenuActive ? `h-screen fixed` : 'min-h-win'} ${className}`}
                style={{
                    top: burgerMenuActive ? `-${burgerMenuOffset}px` : 'auto'
                }}
            >
                <Nav/>
                {burgerMenuActive && <BurgerMenu/>}

                <main className="flex-1 w-screen overflow-x-hidden">
                    {children}
                </main>
                <Footer/>
            </div>
        </ReactLenis>
    )
}